import React from 'react';
//import { useNavigate } from "react-router-dom";


import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



export function signupAction(email, password, navigate) {

    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    //history,
                );
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {
    localStorage.removeItem('userDetails');
    navigate('/login');
    //history.push('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                /*
                displayName: ""
                email: "demo@example.com"
                expireDate: "2024-08-28T18:11:55.287Z"
                expiresIn: "3600"
                idToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNzI0ODY1MTE1LCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE3MjQ4NjUxMTUsImV4cCI6MTcyNDg2ODcxNSwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.MDqVr9IRr5a6-CuESp-CNbx2kmS-Cwsmcp0advuiOJub7jQZ09UboR9GXoSU4LRHxLr4h7Yne4M9GZZH8vb23_DYsCCS1doToozc7Z06AYReVnb5qhe066LtwTtQTAyq5qphzXJyNedoEczyBbmJjXGNyWbciGhZirvgEjudnzWoikmbv0WXkpbJgh43fVuH8VRLX5U7SK8vAgJSto4dB0RPOojsJV_p9BLBKuA7uudGyeJwf6sOCSV4fEaGfXCOWnurzzsGAkCrGUfsKZzZBlTHSruIxzD_8cpTrU1RXlny2-ovhdCgZ-NWxM8ojyC6buF6RbAxK4J6ODSWGRHtsw"
                kind: "identitytoolkit#VerifyPasswordResponse"
                localId: "qmt6dRyipIad8UCc0QpMV2MENSy1"
                refreshToken: "AMf-vBxCIGrP0bgPA3PTSk4wsbPvq_fBY0qzTG24wgFDQymhkQOkvmacT9vPmw-QFTjzqj9PPCNKa5gWkj6sEaDtcKNyVc6sxTGATbrh-rEDs0tLzM0Xa6gLLfubmlyJrZRhROYUeEMKfPxKnV73PyK0RUXYFodJC3VnvIlK5N5N-A13JH_WsJ1KMgdG5gmBONtot6SqHlKiz0xZDBEwuvWXKCK1FoavHg"
                registered: true
                */
                if (response.data.code == 1) {
                    // console.log("responseFromLoginSuccess: ", response.data)
                    saveTokenInLocalStorage(response.data.data);
                    // runLogoutTimer(
                    //     dispatch,
                    //     // response.data.expiresIn * 1000,
                    //     response.data.expiresIn,
                    //     navigate,
                    // );
                    dispatch(loginConfirmedAction(response.data.data));
                    navigate('/');
                }
                else {
                    const errorMessage = formatError(response.data.message);
                    dispatch(loginFailedAction(errorMessage));
                }
                //console.log('kk------1');
                //console.log(kk);
                //console.log(response.data);
                //console.log('kk------2');
                //return response.data;
                //return 'success';
                //history.push('/dashboard');
            })
            .catch((error) => {
                // console.log('errorFromLogin: ', error);
                //console.log(error);
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
