import axios from 'axios';
import DefaultURLs from './DefaultURLs'

class Api {
	axiosInstance;

	constructor() {
		// const hasBasePath = window.location.pathname.indexOf(PATH.BASE_PATH) > -1;

		let baseURL = DefaultURLs.apiBasePointURL;

		// if (hasBasePath) {
		// baseURL = PATH.BASE_PATH + baseURL;
		// }

		// const token = window.localStorage.getItem('VienotToken');
		this.axiosInstance = axios.create({
			baseURL: baseURL,
			timeout: 90000,
			// headers: { Authorization: `Bearer ${token}` } //-> burada problem var çözülecek
		});
	}

	login = (email, password) => {
		return this.axiosInstance.post('auth', { email, password });
	};

	getBrokerCredentials = (unique_id) => {
		return this.axiosInstance.post('users/get-broker-credentials', { unique_id });
	};

	getLogs = (unique_id) => {
		return this.axiosInstance.post('users/logs', { unique_id });
	};

	getPaidUsers = () => {
		return this.axiosInstance.get('users/paid');
	};

	getStrategies = (unique_id) => {
		return this.axiosInstance.get('strategies/' + unique_id);
	};

	createPrivateStrategy = (unique_id, data) => {
		return this.axiosInstance.post('strategies/create-private-strategy',
			{
				unique_id,
				strategy_name: data.strategy_name,
				strategy_pair: data.strategy_pair,
				quantity: data.quantity,
				currency: data.currency,
				market: data.market,
				is_telegram_signal_active: data.is_telegram_signal_active,
				is_bot_active: data.is_bot_active,
				beginning_signal: data.beginning_signal,
				webhook_buy_signals: data.webhook_buy_signals,
				webhook_sell_signals: data.webhook_sell_signals,
				funding_rate_buy_signals: data.funding_rate_buy_signals,
				funding_rate_sell_signals: data.funding_rate_sell_signals
			});
	};

	updatePrivateStrategy = (data) => {
		return this.axiosInstance.post('strategies/update-private-strategy',
			{
				strategy_id: data.strategy_id,
				strategy_name: data.strategy_name,
				strategy_pair: data.strategy_pair,
				quantity: data.quantity,
				currency: data.currency,
				market: data.market,
				is_telegram_signal_active: data.is_telegram_signal_active,
				is_bot_active: data.is_bot_active,
				beginning_signal: data.beginning_signal,
				webhook_buy_signals: data.webhook_buy_signals,
				webhook_sell_signals: data.webhook_sell_signals,
				funding_rate_buy_signals: data.funding_rate_buy_signals,
				funding_rate_sell_signals: data.funding_rate_sell_signals
			});
	};

	confirmStrategy = (unique_id, data) => {
		return this.axiosInstance.post('strategies/confirm',
			{
				unique_id,
				strategy_id: data.strategy_id,
				quantity: data.quantity,
				currency: data.currency,
				market: data.market,
				is_telegram_signal_active: data.is_telegram_signal_active,
				is_bot_active: data.is_bot_active,
			});
	};

	pauseStrategy = (unique_id, strategy_id) => {
		return this.axiosInstance.post('strategies/pause', { unique_id, strategy_id });
	};

	pausePrivateStrategy = (unique_id, strategy_id) => {
		return this.axiosInstance.post('strategies/pause-private', { unique_id, strategy_id });
	};

	startPrivateStrategy = (unique_id, strategy_id) => {
		return this.axiosInstance.post('strategies/start-private', { unique_id, strategy_id });
	};

	sendTelegramPost = (data) => {
		return this.axiosInstance.post('telegram/new-post', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	// addAccessToken = (access_token, id) => {
	// 	return this.axiosInstance.post('auth/add-access-token', {
	// 		access_token,
	// 		id
	// 	});
	// };

	// controlAccessToken = () => {
	// 	return this.axiosInstance.get('auth/control-access-token');
	// };

	// getUsersWithSocialInfos = () => {
	// 	return this.axiosInstance.get('users/get-users-with-social-infos');
	// };

	// getCompetitions = () => {
	// 	return this.axiosInstance.get('competitions');
	// };

	// getCompetitionsForCompany = () => {
	// 	return this.axiosInstance.get('competitions/get-competitions-for-company', {
	// 		headers: {
	// 			Authorization: 'Bearer ' + window.localStorage.getItem('VienotToken')
	// 		}
	// 	});
	// };

	// getCompetitionById = competition_id => {
	// 	return this.axiosInstance.post(
	// 		'competitions/get-competition-by-id',
	// 		{
	// 			competition_id
	// 		},
	// 		{
	// 			headers: {
	// 				Authorization: 'Bearer ' + window.localStorage.getItem('VienotToken')
	// 			}
	// 		}
	// 	);
	// };
}

export default new Api();
