import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>
					{/* <a href="http://dexignzone.com/" target="_blank"  rel="noreferrer">
						DexignZone
					</a>{" "} */}
					© {d.getFullYear()} Uzmandata LLC
				</p>
			</div>
		</div>
	);
};

export default Footer;
