import React, { useState, useEffect, useContext } from 'react';
//import {Tab, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion"
import { Accordion, Button, Col, Container, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import AllOrder from '../Report/History/AllOrder';
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import Api from '../../../services/Api';
import { ThemeContext } from '../../../context/ThemeContext';


const Strategies = () => {
    const cardData = [
        { cardid: '5', title: 'GARAN Strateji', subtitle: '8 İndikatör', icon: 'fa-solid fa-bitcoin-sign', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '50', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Litecoin Strateji', subtitle: '3 İndikatör', icon: 'fa-solid fa-litecoin-sign', link: "https://www.google.com/", profit: '74', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Etherium Strateji', subtitle: '4 İndikatör', icon: 'fa-brands fa-ethereum', link: "https://www.google.com/", profit: '30', profit_factor: "1.67", profitable: "46.54", buy_hold: '50', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Monero Strateji', subtitle: '2 İndikatör', icon: 'fa-brands fa-monero', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Cardano Strateji', subtitle: '10 İndikatör', icon: 'fa-brands fa-digital-ocean', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '20', date: '2 Yıl 10 Gün' },
        { cardid: '5', title: 'Ardor Strateji', subtitle: '12 İndikatör', icon: 'fa-brands fa-airbnb', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '9', title: 'EKGYO Strateji', subtitle: '5 İndikatör', icon: 'fa-brands fa-affiliatetheme', link: "https://www.google.com/", profit: '85', profit_factor: "1.67", profitable: "46.54", buy_hold: '70', date: '2 Yıl 10 Gün' },
        { cardid: '5', title: 'Tether Strateji', subtitle: '7 İndikatör', icon: 'fa-brands fa-steam-symbol', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '9', title: 'THYAO Strateji', subtitle: '9 İndikatör', icon: 'fa-brands fa-korvue', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Arc Strateji', subtitle: '1 İndikatör', icon: 'fa-solid fa-austral-sign', link: "https://www.google.com/", profit: '82', profit_factor: "1.67", profitable: "46.54", buy_hold: '70', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Quantom Strateji', subtitle: '12 İndikatör', icon: 'fa-solid fa-biohazard', link: "https://www.google.com/", profit: '75', profit_factor: "1.67", profitable: "46.54", buy_hold: '60', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Nem Strateji', subtitle: '11 İndikatör', icon: 'fa-solid fa-fan', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '50', date: '2 Yıl 10 Gün' },
        { cardid: '5', title: 'Augur Strateji', subtitle: '3 İndikatör', icon: 'fa-brands fa-atlassian', link: "https://www.google.com/", profit: '90', profit_factor: "1.67", profitable: "46.54", buy_hold: '85', date: '2 Yıl 10 Gün' },
        { cardid: '9', title: 'HEKTS Strateji', subtitle: '1 İndikatör', icon: 'fa-solid fa-atom', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Startis Strateji', subtitle: '12 İndikatör', icon: 'fa-solid fa-layer-group', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '99', date: '2 Yıl 10 Gün' },
        { cardid: '5', title: 'Sango Strateji', subtitle: '13 İndikatör', icon: 'fa-solid fa-lari-sign', link: "https://www.google.com/", profit: '65', profit_factor: "1.67", profitable: "46.54", buy_hold: '75', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Fragmint Strateji', subtitle: '2 İndikatör', icon: 'fa-solid fa-franc-sign', link: "https://www.google.com/", profit: '55', profit_factor: "1.67", profitable: "46.54", buy_hold: '65', date: '2 Yıl 10 Gün' },
        { cardid: '5', title: 'Crypcrade Strateji', subtitle: '5 İndikatör', icon: 'fa-sharp fa-solid fa-copyright', link: "https://www.google.com/", profit: '50', profit_factor: "1.67", profitable: "46.54", buy_hold: '40', date: '2 Yıl 10 Gün' },
        { cardid: '7', title: 'Ameta Strateji', subtitle: '9 İndikatör', icon: 'fa-brands fa-angular', link: "https://www.google.com/", profit: '72', profit_factor: "1.67", profitable: "46.54", buy_hold: '80', date: '2 Yıl 10 Gün' },
        { cardid: '9', title: 'AKBNK Strateji', subtitle: '1 İndikatör', icon: 'fa-brands fa-phoenix-framework', link: "https://www.google.com/", profit: '30', profit_factor: "1.67", profitable: "46.54", buy_hold: '70', date: '2 Yıl 10 Gün' },
    ];

    const { changeBackground } = useContext(ThemeContext);
    const [strategies, setStrategies] = useState()
    const [userStrategies, setUserStrategies] = useState()
    const [privateStrategies, setPrivateStrategies] = useState()
    // const [popular, setPopular] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [activeCategory, setActiveCategory] = useState('all');
    const [copyTradeChecked, setCopyTradeChecked] = useState(false)
    const [strategyModal, setStrategyModal] = useState(false);
    const [ownStrategyModal, setOwnStrategyModal] = useState(false);
    const [editOwnStrategyModal, setEditOwnStrategyModal] = useState(false);
    const [buySignals, setBuySignals] = useState([]);
    const [buyFundingRateSignals, setBuyFundingRateSignals] = useState([]);
    const [sellFundingRateSignals, setSellFundingRateSignals] = useState([]);
    const [sellSignals, setSellSignals] = useState([]);
    const [telegramSignalChecked, setTelegramSignalChecked] = useState(true);
    const [strategyName, setStrategyName] = useState("")
    const [strategyPair, setStrategyPair] = useState("")
    const [orderSize, setOrderSize] = useState(100)
    const [choosenCurrencyType, setChoosenCurrencyType] = useState("try")
    const [choosenMarketType, setChoosenMarketType] = useState("spot")
    const [choosenBeginningSignal, setChoosenBeginningSignal] = useState("buy")

    const strategyBeginningSignalType = [
        { value: 'buy', label: 'Al Sinyali' },
        { value: 'sell', label: 'Sat Sinyali' },
    ]

    const marketType = [
        { value: 'spot', label: 'Spot' },
        { value: 'futures', label: 'Vadeli' },
    ]

    const currencyType = [
        { value: 'try', label: 'TRY' },
        { value: 'usd', label: 'USD' },
    ]

    const fundingRateHigherLowerType = [
        { value: 'higher', label: 'Büyüktür' },
        { value: 'lower', label: 'Küçüktür' }
    ]

    const fundingRateCoins = [
        { value: 'XRPUSD', label: 'XRPUSD' },
        { value: 'ADAUSD', label: 'ADAUSD' },
        { value: 'BTCUSD', label: 'BTCUSD' },
        { value: 'DOTUSD', label: 'DOTUSD' },
        { value: 'EOSUSD', label: 'EOSUSD' },
        { value: 'ETHUSD', label: 'ETHUSD' },
        { value: 'LTCUSD', label: 'LTCUSD' },
        { value: 'MANAUSD', label: 'MANAUSD' }
    ]

    useEffect(() => {
        changeBackground({ value: "dark", label: "Dark" });
    }, [])

    useEffect(() => {
        if (strategies) {
            if (activeCategory === "all") {
                setFiltered(strategies.concat(privateStrategies));
                return;
            }
            if (activeCategory === "my") {
                let combinedMyStrategies = userStrategies.concat(privateStrategies)
                setFiltered(combinedMyStrategies);
                return;
            }
            const filtered = strategies.filter((strategy) =>
                strategy.strategy_category.includes(activeCategory)
            );
            setFiltered(filtered);
        }
        else {
            loadStrategies();
        }
    }, [activeCategory]);

    const loadStrategies = () => {
        const tokenDetailsString = localStorage.getItem('userDetails');
        let tokenDetails = '';
        tokenDetails = JSON.parse(tokenDetailsString);
        Api.getStrategies(tokenDetails.uniqueId).then(response => {
            setStrategies(response.data.data["strategies"])
            setUserStrategies(response.data.data["user_strategies"])
            setPrivateStrategies(response.data.data["private_strategies"])
            if (activeCategory === "all") {
                setFiltered(response.data.data["strategies"].concat(response.data.data["private_strategies"]));
                return;
            }
            if (activeCategory === "my") {
                setFiltered(response.data.data["user_strategies"].concat(response.data.data["private_strategies"]));
                return;
            }
            const filtered = response.data.data["strategies"].filter((strategy) =>
                strategy.strategy_category.includes(activeCategory)
            );
            setFiltered(filtered);
        });
    }

    const addNewBuySignal = () => {
        setBuySignals([...buySignals, { id: buySignals.length + 1, signalName: "", webhook: "" }]);
    };

    const removeBuySignal = (id) => {
        setBuySignals(buySignals.filter(input => input.id !== id));
    };

    const addNewBuyFundingRateSignal = () => {
        setBuyFundingRateSignals([...buyFundingRateSignals, { id: buyFundingRateSignals.length + 1, hl: "higher", pair: "XRPUSD", number: 0 }]);
    };

    const removeBuyFundingRateSignal = (id) => {
        setBuyFundingRateSignals(buyFundingRateSignals.filter(input => input.id !== id));
    };

    const addNewSellSignal = () => {
        setSellSignals([...sellSignals, { id: sellSignals.length + 1, signalName: "", webhook: "" }]);
    };

    const removeSellSignal = (id) => {
        setSellSignals(sellSignals.filter(input => input.id !== id));
    };

    const addNewSellFundingRateSignal = () => {
        setSellFundingRateSignals([...sellFundingRateSignals, { id: sellFundingRateSignals.length + 1, hl: "higher", pair: "XRPUSD", number: 0 }]);
    };

    const removeSellFundingRateSignal = (id) => {
        setSellFundingRateSignals(sellFundingRateSignals.filter(input => input.id !== id));
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Kopyalandı!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    };

    function calculateDateRange(trading_range_start, trading_range_end) {
        // Convert date strings to Date objects
        const startDate = new Date(trading_range_start);
        const endDate = new Date(trading_range_end);

        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate;

        // Convert the time difference from milliseconds to days
        const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // 1 day = 1000 ms * 60 sec * 60 min * 24 hours

        return `${dayDifference} Gün`;
    }

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function createWebhookURL() {
        const tokenDetailsString = localStorage.getItem('userDetails');
        let tokenDetails = '';
        tokenDetails = JSON.parse(tokenDetailsString);
        const randomString = generateRandomString(12);
        return "https://webhook.uzmandata.com/" + tokenDetails.uniqueId + "/" + randomString;
    }

    const handleBuySignalSignalNameChange = (id, event) => {
        const { value } = event.target;
        const updatedSignals = buySignals.map(signal =>
            signal.id === id ? { ...signal, signalName: value, webhook: document.getElementById("webhook-buy-input-" + id).value } : signal
        );
        setBuySignals(updatedSignals);
    };

    const handleBuyFundingRateInputChange = (id) => {
        const updatedSignals = buyFundingRateSignals.map(signal =>
            signal.id === id ? {
                ...signal,
                pair: document.getElementById("pair-buy-funding-rate-" + id).value,
                hl: document.getElementById("hl-buy-funding-rate-" + id).value,
                number: document.getElementById("number-buy-funding-rate-" + id).value
            } : signal
        );
        setBuyFundingRateSignals(updatedSignals);
    };

    const handleSellSignalSignalNameChange = (id, event) => {
        const { value } = event.target;
        const updatedSignals = sellSignals.map(signal =>
            signal.id === id ? { ...signal, signalName: value, webhook: document.getElementById("webhook-sell-input-" + id).value } : signal
        );
        setSellSignals(updatedSignals);
    };

    const handleSellFundingRateInputChange = (id) => {
        const updatedSignals = sellFundingRateSignals.map(signal =>
            signal.id === id ? {
                ...signal,
                pair: document.getElementById("pair-sell-funding-rate-" + id).value,
                hl: document.getElementById("hl-sell-funding-rate-" + id).value,
                number: document.getElementById("number-sell-funding-rate-" + id).value
            } : signal
        );
        setSellFundingRateSignals(updatedSignals);
    };

    const createNewStrategy = () => {
        if (strategyName == "" || strategyName == null) {
            toast.error("Lütfen strateji ismi giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (strategyPair == "" || strategyPair == null) {
            toast.error("Lütfen strateji paritesi giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (buySignals.length == 0 && buyFundingRateSignals.length == 0) {
            toast.error("Stratejide en az 1 adet al sinyali olmalıdır!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (sellSignals.length == 0 && sellFundingRateSignals.length == 0) {
            toast.error("Stratejide en az 1 adet sat sinyali olmalıdır!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (telegramSignalChecked == false && copyTradeChecked == false) {
            toast.error("Lütfen sinyaller gerçekleştiğinde yapılması gereken işlemi seçiniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (copyTradeChecked && orderSize <= 0) {
            toast.error("Lütfen geçerli bir işlem boyutu giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        swal({
            title: "Lütfen stratejinizi oluşturmadan önce içeriğini kontrol ediniz.",
            text:
                `Strateji İsmi: ${strategyName}\n\nStrateji Paritesi: ${strategyPair}\n\nWebhook Al Sinyalleri; \n${buySignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.signalName}\n`)}\n\nFunding Rate Al Sinyalleri; \n${buyFundingRateSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.pair} %${signal.number} ${signal.hl == "lower" ? "Küçüktür" : "Büyüktür"}\n`)}\n\nWebhook Sat Sinyalleri; \n${sellSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.signalName}\n`)}\n\nFunding Rate Sat Sinyalleri; \n${sellFundingRateSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.pair} %${signal.number} ${signal.hl == "lower" ? "Küçüktür" : "Büyüktür"}\n`)}\n\nTelegram Sinyali: ${telegramSignalChecked ? "Evet" : "Hayır"}\n\nOtomatik Al Sat Sinyali: ${copyTradeChecked ? `Evet. \nİşlem Boyutu: ${orderSize} ${choosenCurrencyType}\nPiyasa: ${choosenMarketType}` : "Hayır"}`,
            icon: "warning",
            buttons: ["VAZGEÇ", "BAŞLAT"],
            dangerMode: false,
        }).then((confirm) => {
            if (confirm) {
                let data = {
                    strategy_name: strategyName,
                    strategy_pair: strategyPair,
                    quantity: orderSize,
                    currency: choosenCurrencyType,
                    market: choosenMarketType,
                    is_telegram_signal_active: telegramSignalChecked,
                    is_bot_active: copyTradeChecked,
                    beginning_signal: choosenBeginningSignal,
                    webhook_buy_signals: buySignals,
                    webhook_sell_signals: sellSignals,
                    funding_rate_buy_signals: buyFundingRateSignals,
                    funding_rate_sell_signals: sellFundingRateSignals
                }
                const tokenDetailsString = localStorage.getItem('userDetails');
                let tokenDetails = '';
                tokenDetails = JSON.parse(tokenDetailsString);
                Api.createPrivateStrategy(tokenDetails.uniqueId, data).then(response => {
                    swal("Stratejiniz başarıyla kaydedildi!", { icon: "success" });
                    setOwnStrategyModal(false)
                    setCopyTradeChecked(false)
                    setStrategyName("")
                    setStrategyPair("")
                    setOrderSize(100)
                    setTelegramSignalChecked(1)
                    setBuySignals([])
                    setSellSignals([])
                    setBuyFundingRateSignals([])
                    setSellFundingRateSignals([])
                    loadStrategies();
                });
            }
        })
    }

    const joinCurrentStrategy = () => {
        if (telegramSignalChecked == false && copyTradeChecked == false) {
            toast.error("Lütfen sinyaller gerçekleştiğinde yapılması gereken işlemi seçiniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (copyTradeChecked && orderSize <= 0) {
            toast.error("Lütfen geçerli bir işlem boyutu giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        swal({
            title: "Emin misiniz?",
            text:
                `Copy Trade risklidir ve para kaybedebilirsiniz. Copy Trade Risk Metni`,
            icon: "warning",
            buttons: ["VAZGEÇ", "BAŞLAT"],
            dangerMode: false,
        }).then((confirm) => {
            if (confirm) {
                let data = {
                    strategy_id: strategyModal.id,
                    quantity: orderSize,
                    currency: choosenCurrencyType,
                    market: choosenMarketType,
                    is_telegram_signal_active: telegramSignalChecked,
                    is_bot_active: copyTradeChecked
                }
                const tokenDetailsString = localStorage.getItem('userDetails');
                let tokenDetails = '';
                tokenDetails = JSON.parse(tokenDetailsString);
                Api.confirmStrategy(tokenDetails.uniqueId, data).then(response => {
                    if (response.data.code == 1) {
                        swal(response.data.message, { icon: "success" });
                        setStrategyModal(false)
                        setCopyTradeChecked(false)
                        setOrderSize(100)
                        setTelegramSignalChecked(true)
                        loadStrategies();
                    }
                    else {
                        swal(response.data.message, { icon: "error" });
                    }
                });
            }
        })
    }

    const updatePrivateStrategy = () => {
        if (strategyName == "" || strategyName == null) {
            toast.error("Lütfen strateji ismi giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (strategyPair == "" || strategyPair == null) {
            toast.error("Lütfen strateji paritesi giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (buySignals.length == 0 && buyFundingRateSignals.length == 0) {
            toast.error("Stratejide en az 1 adet al sinyali olmalıdır!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (sellSignals.length == 0 && sellFundingRateSignals.length == 0) {
            toast.error("Stratejide en az 1 adet sat sinyali olmalıdır!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (telegramSignalChecked == false && copyTradeChecked == false) {
            toast.error("Lütfen sinyaller gerçekleştiğinde yapılması gereken işlemi seçiniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        if (copyTradeChecked && orderSize <= 0) {
            toast.error("Lütfen geçerli bir işlem boyutu giriniz!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
            });
            return;
        }
        swal({
            title: "Lütfen stratejinizi güncellemeden önce içeriğini kontrol ediniz.",
            text:
                `Strateji İsmi: ${strategyName}\n\nStrateji Paritesi: ${strategyPair}\n\nWebhook Al Sinyalleri; \n${buySignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.signalName}\n`)}\n\nFunding Rate Al Sinyalleri; \n${buyFundingRateSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.pair} %${signal.number} ${signal.hl == "lower" ? "Küçüktür" : "Büyüktür"}\n`)}\n\nWebhook Sat Sinyalleri; \n${sellSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.signalName}\n`)}\n\nFunding Rate Sat Sinyalleri; \n${sellFundingRateSignals.map((signal, index) => `Sinyal ${index + 1}: ${signal.pair} %${signal.number} ${signal.hl == "lower" ? "Küçüktür" : "Büyüktür"}\n`)}\n\nTelegram Sinyali: ${telegramSignalChecked ? "Evet" : "Hayır"}\n\nOtomatik Al Sat Sinyali: ${copyTradeChecked ? `Evet. \nİşlem Boyutu: ${orderSize} ${choosenCurrencyType}\nPiyasa: ${choosenMarketType}` : "Hayır"}`,
            icon: "warning",
            buttons: ["VAZGEÇ", "KAYDET"],
            dangerMode: false,
        }).then((confirm) => {
            if (confirm) {
                let data = {
                    strategy_id: editOwnStrategyModal.id,
                    strategy_name: strategyName,
                    strategy_pair: strategyPair,
                    quantity: orderSize,
                    currency: choosenCurrencyType,
                    market: choosenMarketType,
                    is_telegram_signal_active: telegramSignalChecked,
                    is_bot_active: copyTradeChecked,
                    beginning_signal: choosenBeginningSignal,
                    webhook_buy_signals: buySignals,
                    webhook_sell_signals: sellSignals,
                    funding_rate_buy_signals: buyFundingRateSignals,
                    funding_rate_sell_signals: sellFundingRateSignals
                }
                const tokenDetailsString = localStorage.getItem('userDetails');
                let tokenDetails = '';
                tokenDetails = JSON.parse(tokenDetailsString);
                Api.updatePrivateStrategy(data).then(response => {
                    swal("Stratejiniz başarıyla güncellendi!", { icon: "success" });
                    setEditOwnStrategyModal(false)
                    setCopyTradeChecked(false)
                    setStrategyName("")
                    setStrategyPair("")
                    setOrderSize(100)
                    setTelegramSignalChecked(1)
                    setBuySignals([])
                    setSellSignals([])
                    setBuyFundingRateSignals([])
                    setSellFundingRateSignals([])
                    loadStrategies();
                });
            }
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-xl-12">
                    <div className="card Infra">
                        <div className="card-header border-0">
                            <div className="site-filters clearfix center m-b40">
                                <ul className="filters" data-bs-toggle="buttons">
                                    <li data-filter="" className={`btn ${activeCategory === "all" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveCategory("all")}>Tüm Stratejiler</Link>
                                    </li>
                                    <li data-filter=".gaming" className={`btn ${activeCategory === "crypto" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveCategory("crypto")}>Kripto Stratejiler</Link>
                                    </li>
                                    <li data-filter=".trade" className={`btn ${activeCategory === "bist" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveCategory("bist")}>BİST Stratejiler</Link>
                                    </li>
                                    <li data-filter=".infra" className={`btn ${activeCategory === "my" ? "active" : ""}`}>
                                        <Link to={"#"} className="site-button" onClick={() => setActiveCategory("my")}>Benim Stratejilerim</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="site-filters clearfix center m-b40">
                                <ul className="filters" data-bs-toggle="buttons">
                                    <li data-filter=".trade" className="btn btn-info fa-plus p-1">
                                        <span onClick={() => setOwnStrategyModal(true)}>Yeni Strateji Oluştur</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <Modal className="fade" size="lg" show={strategyModal}>
                                <Modal.Header>
                                    <Modal.Title>{strategyModal.strategy_name}</Modal.Title>
                                    <Button
                                        variant=""
                                        className="btn-close"
                                        onClick={() => {
                                            setStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                    />
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="card profile-card card-bx m-b30">
                                        <div className="card-header">
                                            <h6 className="text-black">Risk açıklaması. Copy Trade risklidir. Lütfen kaybetmekten çekinmeyeceğiniz miktarlarla oynayınız.</h6>
                                        </div>
                                        <form className="profile-form">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked
                                                                className="form-check-input"
                                                                id="telegramSinyaliCheckbox"
                                                                value={telegramSignalChecked}
                                                                onChange={(event) => setTelegramSignalChecked(event.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="telegramSinyaliCheckbox"
                                                            >
                                                                Telegram Sinyali Al
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="copyTradeCheckbox"
                                                                value={copyTradeChecked}
                                                                onClick={(item) => setCopyTradeChecked(item.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="copyTradeCheckbox"
                                                            >
                                                                Otomatik Al Sat Yap
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={!copyTradeChecked ? { display: "none" } : { display: "" }} className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">İşlem Boyutu</label>
                                                        <div className='row'>
                                                            <div className="col-sm-6">
                                                                <input type="number" value={orderSize} onChange={(event) => setOrderSize(event.target.value)} className="form-control" placeholder="100" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <Select options={currencyType} className="custom-react-select"
                                                                    defaultValue={currencyType[0]}
                                                                    isSearchable={false}
                                                                    onChange={(event) => setChoosenCurrencyType(event.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Piyasa</label>
                                                        <Select options={marketType} className="custom-react-select"
                                                            defaultValue={marketType[0]}
                                                            isSearchable={false}
                                                            onChange={(event) => setChoosenMarketType(event.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-success" onClick={() => joinCurrentStrategy()}>Başlat</button>
                                    <Button
                                        onClick={() => {
                                            setStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                        variant="danger"
                                    >
                                        Kapat
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal className="fade" size="lg" show={ownStrategyModal}>
                                <Modal.Header>
                                    <Modal.Title>Yeni Strateji Oluştur</Modal.Title>
                                    <Button
                                        variant=""
                                        className="btn-close"
                                        onClick={() => {
                                            setOwnStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                    />
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="card profile-card card-bx m-b30">
                                        <div className="card-header">
                                            <h6 className="text-black">TradingView alarmlarınızı kullanarak istediğiniz indikatörleri birleştirin ve kendi stratejinizi oluşturun.</h6>
                                        </div>
                                        <form className="profile-form">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Strateji İsmi</label>
                                                        <input type="text" className="form-control" value={strategyName} onChange={(item) => setStrategyName(item.target.value)} placeholder='Bitcoin 1 Saatlik Stratejim' />
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Parite</label>
                                                        <input type="text" className="form-control" value={strategyPair} onChange={(item) => setStrategyPair(item.target.value)} placeholder='Örn: BTCUSDT veya GARAN' />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 m-b30">
                                                        <Accordion className="accordion accordion-primary" defaultActiveKey="0">
                                                            <Accordion.Item className="accordion-item" key={0} eventKey='0'>
                                                                <Accordion.Header className="accordion-header rounded-lg">
                                                                    Al Sinyalleri
                                                                </Accordion.Header>
                                                                <Accordion.Collapse eventKey="0">
                                                                    <div className="accordion-body">
                                                                        {buySignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}.</span>
                                                                                <input type="text" className="form-control" onChange={(event) => handleBuySignalSignalNameChange(input.id, event)} placeholder="Sinyal İsmi" />
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    id={"webhook-buy-input-" + input.id}
                                                                                    defaultValue={createWebhookURL()}
                                                                                />
                                                                                <span
                                                                                    onClick={() => copyToClipboard(document.getElementById("webhook-buy-input-" + input.id).value)}
                                                                                    className="fa-solid fa-copy fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                ></span>
                                                                                {index !== 0 && (
                                                                                    <span
                                                                                        onClick={() => removeBuySignal(input.id)}
                                                                                        className="fs-18 input-group-text"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >X</span>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        {buyFundingRateSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}. FR</span>
                                                                                <input type="number"
                                                                                    id={"number-buy-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    className="form-control"
                                                                                    placeholder="0" />
                                                                                <select
                                                                                    id={"pair-buy-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={fundingRateCoins[0]}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateCoins.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <select
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateHigherLowerType.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {/* <Select options={fundingRateHigherLowerType} className="form-control custom-react-select"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    isSearchable={false}
                                                                                /> */}
                                                                                <span
                                                                                    onClick={() => removeBuyFundingRateSignal(input.id)}
                                                                                    className="fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >X</span>
                                                                            </div>
                                                                        ))}
                                                                        <span className='btn btn-info' onClick={addNewBuySignal}>Yeni İndikatör Sinyali Ekle</span>
                                                                        <span className='btn btn-info' style={{ marginLeft: 5 }} onClick={addNewBuyFundingRateSignal}>Yeni Funding Rate Sinyali Ekle</span>
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </Accordion.Item>
                                                            <Accordion.Item className="accordion-item" key={1} eventKey='1'>
                                                                <Accordion.Header className="accordion-header rounded-lg">
                                                                    Sat Sinyalleri
                                                                </Accordion.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <div className="accordion-body">
                                                                        {sellSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}.</span>
                                                                                <input type="text" onChange={(event) => handleSellSignalSignalNameChange(input.id, event)} className="form-control" placeholder="Sinyal İsmi" />
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    id={"webhook-sell-input-" + input.id}
                                                                                    defaultValue={createWebhookURL()}
                                                                                />
                                                                                <span
                                                                                    onClick={() => copyToClipboard(document.getElementById("webhook-sell-input-" + input.id).value)}
                                                                                    className="fa-solid fa-copy fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                ></span>
                                                                                {index !== 0 && (
                                                                                    <span
                                                                                        onClick={() => removeSellSignal(input.id)}
                                                                                        className="fs-18 input-group-text"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >X</span>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        {sellFundingRateSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}. FR</span>
                                                                                <input type="number"
                                                                                    id={"number-sell-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                    className="form-control"
                                                                                    placeholder="0" />
                                                                                <select
                                                                                    id={"pair-sell-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={fundingRateCoins[0]}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateCoins.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <select
                                                                                    id={"hl-sell-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateHigherLowerType.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {/* <Select options={fundingRateHigherLowerType} className="form-control custom-react-select"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    isSearchable={false}
                                                                                /> */}
                                                                                <span
                                                                                    onClick={() => removeSellFundingRateSignal(input.id)}
                                                                                    className="fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >X</span>
                                                                            </div>
                                                                        ))}
                                                                        <span className='btn btn-info mt-10' onClick={addNewSellSignal}>Yeni Sinyal Ekle</span>
                                                                        <span className='btn btn-info' style={{ marginLeft: 5 }} onClick={addNewSellFundingRateSignal}>Yeni Funding Rate Sinyali Ekle</span>
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={telegramSignalChecked}
                                                                value={telegramSignalChecked}
                                                                className="form-check-input"
                                                                id="telegramSinyaliCheckboxOwnStrategy"
                                                                onChange={(event) => setTelegramSignalChecked(event.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="telegramSinyaliCheckboxOwnStrategy"
                                                            >
                                                                Telegram Sinyali Al
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="copyTradeCheckbox"
                                                                value={copyTradeChecked}
                                                                onClick={(item) => setCopyTradeChecked(item.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="copyTradeCheckbox"
                                                            >
                                                                Otomatik Al Sat Yap
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={!copyTradeChecked ? { display: "none" } : { display: "" }} className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">İşlem Boyutu</label>
                                                        <div className='row'>
                                                            <div className="col-sm-6">
                                                                <input type="number" value={orderSize} onChange={(event) => setOrderSize(event.target.value)} className="form-control" placeholder="100" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <Select options={currencyType} className="custom-react-select"
                                                                    defaultValue={currencyType[0]}
                                                                    isSearchable={false}
                                                                    onChange={(event) => setChoosenCurrencyType(event.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Piyasa</label>
                                                        <Select options={marketType} className="custom-react-select"
                                                            defaultValue={marketType[0]}
                                                            isSearchable={false}
                                                            onChange={(event) => setChoosenMarketType(event.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Başlangıç Sinyali</label>
                                                        <Select options={strategyBeginningSignalType} className="custom-react-select"
                                                            defaultValue={strategyBeginningSignalType[0]}
                                                            isSearchable={false}
                                                            onChange={(event) => setChoosenBeginningSignal(event.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-success" onClick={() => createNewStrategy()}>Oluştur</button>
                                    <Button
                                        onClick={() => {
                                            setOwnStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                        variant="danger"
                                    >
                                        Kapat
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal className="fade" size="lg" show={editOwnStrategyModal}>
                                <Modal.Header>
                                    <Modal.Title>Stratejiyi Düzenle</Modal.Title>
                                    <Button
                                        variant=""
                                        className="btn-close"
                                        onClick={() => {
                                            setEditOwnStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                    />
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="card profile-card card-bx m-b30">
                                        <div className="card-header">
                                            <h6 className="text-black">TradingView alarmlarınızı kullanarak istediğiniz indikatörleri birleştirin ve kendi stratejinizi oluşturun.</h6>
                                        </div>
                                        <form className="profile-form">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Strateji İsmi</label>
                                                        <input type="text" className="form-control" value={strategyName} onChange={(item) => setStrategyName(item.target.value)} placeholder='Bitcoin 1 Saatlik Stratejim' />
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Parite</label>
                                                        <input type="text" className="form-control" value={strategyPair} onChange={(item) => setStrategyPair(item.target.value)} placeholder='Örn: BTCUSDT veya GARAN' />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 m-b30">
                                                        <Accordion className="accordion accordion-primary" defaultActiveKey="0">
                                                            <Accordion.Item className="accordion-item" key={0} eventKey='0'>
                                                                <Accordion.Header className="accordion-header rounded-lg">
                                                                    Al Sinyalleri
                                                                </Accordion.Header>
                                                                <Accordion.Collapse eventKey="0">
                                                                    <div className="accordion-body">
                                                                        {buySignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}.</span>
                                                                                <input type="text" value={input.signalName} className="form-control" onChange={(event) => handleBuySignalSignalNameChange(input.id, event)} placeholder="Sinyal İsmi" />
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    id={"webhook-buy-input-" + input.id}
                                                                                    defaultValue={input.webhook}
                                                                                />
                                                                                <span
                                                                                    onClick={() => copyToClipboard(document.getElementById("webhook-buy-input-" + input.id).value)}
                                                                                    className="fa-solid fa-copy fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                ></span>
                                                                                {index !== 0 && (
                                                                                    <span
                                                                                        onClick={() => removeBuySignal(input.id)}
                                                                                        className="fs-18 input-group-text"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >X</span>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        {buyFundingRateSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}. FR</span>
                                                                                <input type="number"
                                                                                    id={"number-buy-funding-rate-" + input.id}
                                                                                    value={input.number}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    className="form-control"
                                                                                    placeholder="0" />
                                                                                <select
                                                                                    id={"pair-buy-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={input.pair}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateCoins.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <select
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={input.hl}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateHigherLowerType.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {/* <Select options={fundingRateHigherLowerType} className="form-control custom-react-select"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    isSearchable={false}
                                                                                /> */}
                                                                                <span
                                                                                    onClick={() => removeBuyFundingRateSignal(input.id)}
                                                                                    className="fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >X</span>
                                                                            </div>
                                                                        ))}
                                                                        <span className='btn btn-info' onClick={addNewBuySignal}>Yeni İndikatör Sinyali Ekle</span>
                                                                        <span className='btn btn-info' style={{ marginLeft: 5 }} onClick={addNewBuyFundingRateSignal}>Yeni Funding Rate Sinyali Ekle</span>
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </Accordion.Item>
                                                            <Accordion.Item className="accordion-item" key={1} eventKey='1'>
                                                                <Accordion.Header className="accordion-header rounded-lg">
                                                                    Sat Sinyalleri
                                                                </Accordion.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <div className="accordion-body">
                                                                        {sellSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}.</span>
                                                                                <input type="text" value={input.signalName} onChange={(event) => handleSellSignalSignalNameChange(input.id, event)} className="form-control" placeholder="Sinyal İsmi" />
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    id={"webhook-sell-input-" + input.id}
                                                                                    defaultValue={input.webhook}
                                                                                />
                                                                                <span
                                                                                    onClick={() => copyToClipboard(document.getElementById("webhook-sell-input-" + input.id).value)}
                                                                                    className="fa-solid fa-copy fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                ></span>
                                                                                {index !== 0 && (
                                                                                    <span
                                                                                        onClick={() => removeSellSignal(input.id)}
                                                                                        className="fs-18 input-group-text"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >X</span>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                        {sellFundingRateSignals.map((input, index) => (
                                                                            <div className="input-group input-success mb-3" key={input.id}>
                                                                                <span className="input-group-text">{index + 1}. FR</span>
                                                                                <input type="number"
                                                                                    id={"number-sell-funding-rate-" + input.id}
                                                                                    value={input.number}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                    className="form-control"
                                                                                    placeholder="0" />
                                                                                <select
                                                                                    id={"pair-sell-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={input.pair}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateCoins.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <select
                                                                                    id={"hl-sell-funding-rate-" + input.id}
                                                                                    className="custom-react-select form-control"
                                                                                    defaultValue={input.hl}
                                                                                    onChange={(event) => handleSellFundingRateInputChange(input.id)}
                                                                                >
                                                                                    {fundingRateHigherLowerType.map((item, key) => (
                                                                                        <option key={key} value={item.value}>{item.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {/* <Select options={fundingRateHigherLowerType} className="form-control custom-react-select"
                                                                                    defaultValue={fundingRateHigherLowerType[0]}
                                                                                    id={"hl-buy-funding-rate-" + input.id}
                                                                                    onChange={(event) => handleBuyFundingRateInputChange(input.id)}
                                                                                    isSearchable={false}
                                                                                /> */}
                                                                                <span
                                                                                    onClick={() => removeSellFundingRateSignal(input.id)}
                                                                                    className="fs-18 input-group-text"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >X</span>
                                                                            </div>
                                                                        ))}
                                                                        <span className='btn btn-info mt-10' onClick={addNewSellSignal}>Yeni Sinyal Ekle</span>
                                                                        <span className='btn btn-info' style={{ marginLeft: 5 }} onClick={addNewSellFundingRateSignal}>Yeni Funding Rate Sinyali Ekle</span>
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={editOwnStrategyModal.is_telegram_signal_active}
                                                                value={editOwnStrategyModal.is_telegram_signal_active}
                                                                className="form-check-input"
                                                                id="telegramSinyaliCheckboxOwnStrategy"
                                                                onChange={(event) => setTelegramSignalChecked(event.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="telegramSinyaliCheckboxOwnStrategy"
                                                            >
                                                                Telegram Sinyali Al
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <div className="form-check custom-checkbox mb-3 checkbox-info">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="copyTradeCheckbox"
                                                                defaultChecked={editOwnStrategyModal.is_bot_active}
                                                                value={editOwnStrategyModal.is_bot_active}
                                                                onClick={(item) => setCopyTradeChecked(item.target.checked)}
                                                            />
                                                            <label
                                                                className="form-check-label text-black"
                                                                htmlFor="copyTradeCheckbox"
                                                            >
                                                                Otomatik Al Sat Yap
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={!copyTradeChecked ? { display: "none" } : { display: "" }} className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">İşlem Boyutu</label>
                                                        <div className='row'>
                                                            <div className="col-sm-6">
                                                                <input type="number" value={orderSize} onChange={(event) => setOrderSize(event.target.value)} className="form-control" placeholder="100" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <Select options={currencyType} className="custom-react-select"
                                                                    defaultValue={currencyType[0]}
                                                                    isSearchable={false}
                                                                    onChange={(event) => setChoosenCurrencyType(event.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Piyasa</label>
                                                        <Select options={marketType} className="custom-react-select"
                                                            defaultValue={marketType[0]}
                                                            isSearchable={false}
                                                            onChange={(event) => setChoosenMarketType(event.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b30">
                                                        <label className="form-label">Başlangıç Sinyali</label>
                                                        <Select options={strategyBeginningSignalType} className="custom-react-select"
                                                            defaultValue={strategyBeginningSignalType[0]}
                                                            isSearchable={false}
                                                            onChange={(event) => setChoosenBeginningSignal(event.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-success" onClick={() => updatePrivateStrategy()}>Güncelle</button>
                                    <Button
                                        onClick={() => {
                                            setEditOwnStrategyModal(false)
                                            setCopyTradeChecked(false)
                                        }}
                                        variant="danger"
                                    >
                                        Kapat
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <ul
                                id="masonry" className="row"
                            //transition={{ duration: 0.3 }}
                            >
                                <AnimatePresence>
                                    {strategies && filtered.map((item, index) => {
                                        return (
                                            <motion.li layout initial={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0, opacity: 0 }} transition={{ duration: 0.2 }} className="col-xl-3 col-md-6 infra rated px-3" key={index}
                                            //transition={{ duration: 0.5 }}
                                            >
                                                <div className="card pull-up" style={item.beginning_signal && { border: 'gold 2px solid' }}>
                                                    <div className="card-body align-items-center flex-wrap">
                                                        <div className="d-flex align-items-center mb-4">
                                                            {/* <Link to={"#"} className="ico-icon">
                                                                <i className={item.icon}></i>
                                                            </Link> */}
                                                            <div>
                                                                <h4 className="heading mb-0 fs-24" >{item.strategy_name}</h4>
                                                                {item.beginning_signal ? <p className="mb-0 fs-14 text-black">{item.strategy_pair}</p> : <OverlayTrigger
                                                                    trigger="hover"
                                                                    placement={"top"}
                                                                    responsive={true}
                                                                    overlay={
                                                                        <Tooltip className='toltip-popover' id="popover-positioned-top">
                                                                            <h3 className='popover-header'>Strateji İçeriği</h3>
                                                                            <strong>
                                                                                {item.strategy_description}
                                                                            </strong>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button className='btn' style={{ fontSize: "10px", background: "gray", color: "white", padding: 3 }}>İçeriği İncele</button>
                                                                </OverlayTrigger>}
                                                            </div>
                                                            <div className="ms-4 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <i onClick={() => userStrategies.find(userStrategy => userStrategy.strategy_id == item.id) ?
                                                                        swal({
                                                                            title: item.beginning_signal ? (item.is_running ? "Stratejiyi durdurmak istediğinize emin misiniz?" : "Stratejiyi başlatmak istediğinize emin misiniz?") : "Stratejiden ayrılmak istediğinize emin misiniz?",
                                                                            text:
                                                                                item.beginning_signal ? item.is_running ? "Onayladığınız takdirde strateji durdurulacaktır." : "Onayladığınız takdirde strateji başlatılacaktır." : `Onayladığınız takdirde strateji başkalarının erişimine açılacaktır.`,
                                                                            icon: "warning",
                                                                            buttons: ["VAZGEÇ", item.beginning_signal ? item.is_running ? "DURDUR" : "BAŞLAT" : "AYRIL"],
                                                                            dangerMode: true,
                                                                        }).then((confirm) => {
                                                                            if (confirm) {
                                                                                const tokenDetailsString = localStorage.getItem('userDetails');
                                                                                let tokenDetails = '';
                                                                                tokenDetails = JSON.parse(tokenDetailsString);
                                                                                if (item.beginning_signal) {
                                                                                    if (item.is_running) {
                                                                                        Api.pausePrivateStrategy(tokenDetails.uniqueId, item.id).then(response => {
                                                                                            swal("Stratejiyi durdurdunuz!", { icon: "success" });
                                                                                            loadStrategies();
                                                                                        });
                                                                                    }
                                                                                    else {
                                                                                        Api.startPrivateStrategy(tokenDetails.uniqueId, item.id).then(response => {
                                                                                            swal("Stratejiyi başlattınız!", { icon: "success" });
                                                                                            loadStrategies();
                                                                                        });
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    Api.pauseStrategy(tokenDetails.uniqueId, item.id).then(response => {
                                                                                        swal("Stratejiden ayrıldınız!", { icon: "success" });
                                                                                        loadStrategies();
                                                                                    });
                                                                                }
                                                                            }
                                                                        }) : setStrategyModal(item)}
                                                                        className={item.beginning_signal ? `fa-solid fa-${item.is_running ? "pause" : "play"} fs-24 p-2 m-2 btn btn-${item.is_running ? "warning" : "info"}` : userStrategies.find(userStrategy => userStrategy.strategy_id == item.id) ? "fa-solid fa-pause fs-24 p-2 m-2 btn btn-warning" : "fa-solid fa-eye fs-24 p-2 m-2 btn btn-primary"}
                                                                    ></i>
                                                                </div>
                                                                {item.beginning_signal && <div><i onClick={() => {
                                                                    setStrategyName(item.strategy_name)
                                                                    setStrategyPair(item.strategy_pair)
                                                                    setChoosenBeginningSignal(item.beginning_signal)
                                                                    setTelegramSignalChecked(item.is_telegram_signal_active)
                                                                    setCopyTradeChecked(item.is_bot_active)
                                                                    setChoosenMarketType(item.market)
                                                                    setChoosenCurrencyType(item.currency)
                                                                    setOrderSize(item.quantity)
                                                                    let webhookBuySignals = []
                                                                    let webhookSellSignals = []
                                                                    item.webhook_signals.map((signal) => {
                                                                        if (signal.type == "buy") {
                                                                            webhookBuySignals.push({
                                                                                id: signal.id,
                                                                                signalName: signal.signal_name,
                                                                                webhook: signal.webhook_url
                                                                            })
                                                                            setBuySignals(webhookBuySignals);
                                                                        }
                                                                        else {
                                                                            webhookSellSignals.push({
                                                                                id: signal.id,
                                                                                signalName: signal.signal_name,
                                                                                webhook: signal.webhook_url
                                                                            })
                                                                            setSellSignals(webhookSellSignals);
                                                                        }
                                                                    })
                                                                    let fundingBuySignals = []
                                                                    let fundingSellSignals = []
                                                                    item.funding_rate_signals.map((signal) => {
                                                                        if (signal.type == "buy") {
                                                                            fundingBuySignals.push({
                                                                                id: signal.id,
                                                                                hl: signal.hl,
                                                                                number: signal.number,
                                                                                pair: signal.pair
                                                                            })
                                                                            setBuyFundingRateSignals(fundingBuySignals);
                                                                        }
                                                                        else {
                                                                            fundingSellSignals.push({
                                                                                id: signal.id,
                                                                                hl: signal.hl,
                                                                                number: signal.number,
                                                                                pair: signal.pair
                                                                            })
                                                                            setSellFundingRateSignals(fundingSellSignals);
                                                                        }
                                                                    })
                                                                    setEditOwnStrategyModal(item)
                                                                }} className="fa-solid fa-edit fs-24 p-2 m-2 btn btn-info"></i></div>}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  justify-content-between">
                                                            {item.beginning_signal
                                                                ?
                                                                <>
                                                                    <div>
                                                                        <p className="mb-0 fs-14 text-success">{item.is_telegram_signal_active ? "Telegram Aktif" : "Telegram Pasif"}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className={`text-center mb-0 fs-14 text-${item.is_bot_active ? "success" : "black"}`}>{item.is_bot_active ? "Oto Al Sat Aktif" : "Oto Al Sat Pasif"}</p>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div>
                                                                        <p className="mb-0 fs-14 text-success">%{item.net_profit} Kâr</p>
                                                                        <span className="fs-12 text-black">%{item.percent_profitable} Kârlılık</span>
                                                                    </div>
                                                                    <div>
                                                                        <p className="mb-0 fs-14 text-black">{item.profit_factor} Katsayı</p>
                                                                        <span className="fs-12 text-black">{calculateDateRange(item.trading_range_start, item.trading_range_end)}</span>
                                                                    </div>
                                                                    <div>
                                                                        <p className="mb-0 fs-14 text-black">%{item.buy_and_hold_return} Al&Tut</p>
                                                                        <a href={item.tradingview_backtest_link} target='_blank' className="fs-12 text-decoration-underline">İncele</a>
                                                                    </div>
                                                                </>}
                                                        </div>
                                                        {item.beginning_signal &&
                                                            <>
                                                                <div className="text-center mb-0 fs-14 text-black" style={item.beginning_signal == "buy" ? { background: "#9568ff", borderRadius: "10px" } : {}}>Al:
                                                                    {item.webhook_signals.map((signal) => {
                                                                        if (signal.type == "buy") {
                                                                            return (<OverlayTrigger trigger="hover" placement={"top"} responsive={true}
                                                                                overlay={
                                                                                    <Tooltip className='toltip-popover' id="popover-positioned-top">
                                                                                        <h3 className='popover-header'>Webhook Sinyal İçeriği</h3>
                                                                                        <strong>
                                                                                            İsim: {signal.signal_name}
                                                                                        </strong>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <i className={`fa-solid fa-lightbulb fs-6 p-1 m-1 btn btn-${signal.status == 'positive' ? "success" : "light"}`}></i>
                                                                            </OverlayTrigger>)
                                                                        }
                                                                    })}
                                                                    {item.funding_rate_signals.map((signal) => {
                                                                        if (signal.type == "buy") {
                                                                            return (<OverlayTrigger trigger="hover" placement={"top"} responsive={true}
                                                                                overlay={
                                                                                    <Tooltip className='toltip-popover' id="popover-positioned-top">
                                                                                        <h3 className='popover-header'>Funding Rate Sinyal İçeriği</h3>
                                                                                        <strong>
                                                                                            Parite: {signal.pair} - Yüzde: %{signal.number} - Seviye: {signal.hl == "higher" ? "Büyüktür" : "Küçüktür"}
                                                                                        </strong>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <i className={`fa-solid fa-lightbulb fs-6 p-1 m-1 btn btn-${signal.status == 'positive' ? "success" : "light"}`}></i>
                                                                            </OverlayTrigger>)
                                                                        }
                                                                    })}
                                                                </div>
                                                                <div className="text-center mb-0 fs-14 text-black" style={item.beginning_signal == "sell" ? { background: "#9568ff", borderRadius: "10px" } : {}}>Sat:
                                                                    {item.webhook_signals.map((signal) => {
                                                                        if (signal.type == "sell") {
                                                                            return (<OverlayTrigger trigger="hover" placement={"top"} responsive={true}
                                                                                overlay={
                                                                                    <Tooltip className='toltip-popover' id="popover-positioned-top">
                                                                                        <h3 className='popover-header'>Webhook Sinyal İçeriği</h3>
                                                                                        <strong>
                                                                                            İsim: {signal.signal_name}
                                                                                        </strong>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <i className={`fa-solid fa-lightbulb fs-6 p-1 m-1 btn btn-${signal.status == 'positive' ? "danger" : "light"}`}></i>
                                                                            </OverlayTrigger>)
                                                                        }
                                                                    })}
                                                                    {item.funding_rate_signals.map((signal) => {
                                                                        if (signal.type == "sell") {
                                                                            return (<OverlayTrigger trigger="hover" placement={"top"} responsive={true}
                                                                                overlay={
                                                                                    <Tooltip className='toltip-popover' id="popover-positioned-top">
                                                                                        <h3 className='popover-header'>Funding Rate Sinyal İçeriği</h3>
                                                                                        <strong>
                                                                                            Parite: {signal.pair} - Yüzde: %{signal.number} - Seviye: {signal.hl == "higher" ? "Büyüktür" : "Küçüktür"}
                                                                                        </strong>
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <i className={`fa-solid fa-lightbulb fs-6 p-1 m-1 btn btn-${signal.status == 'positive' ? "danger" : "light"}`}></i>
                                                                            </OverlayTrigger>)
                                                                        }
                                                                    })}
                                                                </div>
                                                            </>}
                                                    </div>
                                                </div>
                                            </motion.li>
                                        )
                                    })}
                                </AnimatePresence>
                            </ul>
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}
export default Strategies;